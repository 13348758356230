// Disable site in iframe
window.isIfr = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

if (isIfr()) {
  setTimeout(() => {
    document.body.innerHTML = '';
  }, 0);
}


/* =============================
*  NEW Widget INIT
================================ */
$.getScript('/assets/js/calc.js', function () {

	if ($('[data-calc-mini]').length > 0 && $('.calc-mini-json').length > 0) {
		new Calc({
			ID: '54',
			calculatorType: 'DefaultCalculator',
			format: 'json',
			options: {
				deafault: {
					deadlinesDefault: [68197],
					academicLevelDefault: 1,
					paperTypesDefault: 1,
					pagesDefault: 1,
				}
			}
		});
	}

	if ($('[data-calc-mini]').length > 0 && $('.calc-mini-html').length > 0) {
		new Calc({
			ID: '54',
			calculatorType: 'DefaultCalculator',
			format: 'html',
			options: {
				deafault: {
					deadlinesDefault: [68243],
					academicLevelDefault: 2,
					paperTypesDefault: 1,
					pagesDefault: 2,
				}
			}
		});
	}

  if ($('[data-calc-short]').length > 0) {
    new Calc({
      ID: '54',
      calculatorType: 'ShortCalculator',
      format: 'json',
      options: {
        deafault: {
          deadlinesDefault: [68197, 68243, 68241, 68245, 68239],
          academicLevelDefault: 1,
          paperTypesDefault: 1,
          pagesDefault: 1,
        }
      }
    });
  }
});

//LCO
$.getScript('/assets/js/lcp.js', function() {
  if($('[data-latest-completed]').length > 0) {
    new LCP({
      ID: '54',
      format: 'html',
      fields: ['orderId', 'topicTitle', 'discipline', 'academicLevel', 'pages', 'delivered'],
    });
  }
});

/* =============================
*  end NEW Widget INIT
================================ */


/* ==========================================================================
   2. Custom JS for bestessaycheap.com
   ========================================================================== */
/*
   Toggle list
   ========================================================================== */

function toggle_list() {
  $('.toggle-list .toggle-content').addClass('hide');
  $(document).on('click', '.toggle-list .toggle-link', function () {
    if ($(this).next().hasClass('hide')) {
      $(this).next().slideDown(500);
    } else {
      $(this).next().slideUp(500);
    }
    $(this).parent().toggleClass('active');
    $(this).next().toggleClass('hide');
    return false;
  });
}

var toggleList = document.querySelector('.toggle-list');
var commonPages = document.getElementById('CommonPage');

//Init
if (toggleList) {
  toggle_list();
}

var check_commonPages_loaded = setInterval(checkCommonPagesLoaded, 100);

// Check if page Load through Common Page API
function checkCommonPagesLoaded() {
  if (commonPages && commonPages.children.length > 0) {
    clearInterval(check_commonPages_loaded);
    //Init
    toggle_list();
  }
}


$(function() {

	/* -------------------- Menu ---------------------------- */
	var k = 8;
	$('nav .ro1 > li > a').each(function() {
		$(this).css('z-index', k);
		--k;
	});

	var k = 4;
	$('nav .ro2 > li > a').each(function() {
		$(this).css('z-index', k);
		--k;
	});

	$('nav ul > li > a').each(function() {
		if ($(this).attr('href') == window.location.pathname) {
			$(this).addClass('active');
			$(this).css('z-index', '11');
		}
	});

	/* -------------------- Follow Us ---------------------------- */

	$('#fl-btn').click(function() {
		$('.follow-us .texts *').fadeToggle("fast").delay(800).fadeToggle("fast");
		if ($(document).width() < 490) {
			$('.follow-us .texts').delay(400).animate({
				height: 'toggle'
			});
		} else {
			$('.follow-us .texts').delay(400).animate({
				width: 'toggle'
			});
		}
		return false;
	});

	/* -------------------- Scroll Left List ---------------------------- */
	$('#scroll-btn').click(function() {
		$('.scrool-menu-down').slideToggle('slow');
		$('#scroll-btn').toggleClass("scroll-btn-class1");
		return false;
	});


	/* -------------------- Testimonials Height ---------------------------- */
	var testHeight = $('#center .mini-testimonials .testimonials:first p');
	var testHeight2 = $('#center .mini-testimonials .testimonials:last p');

	if (testHeight.height() < testHeight2.height()) {
		$('#center .mini-testimonials .testimonials p').css('height', testHeight2.height())
	} else {
		$('#center .mini-testimonials .testimonials p').css('height', testHeight.height())
	};

	$(window).resize(function() {

		testHeight = $('#center .mini-testimonials .testimonials:first p');
		testHeight2 = $('#center .mini-testimonials .testimonials:last p');
		testHeight.css('height', 'auto');
		testHeight2.css('height', 'auto');
		if (testHeight.height() < testHeight2.height()) {
			$('#center .mini-testimonials .testimonials p').css('height',
				testHeight2.height())
		} else {
			$('#center .mini-testimonials .testimonials p').css('height', testHeight
				.height())
		};
	})

	/* -------------------- FAQ + MoneyBack + Pricing List ---------------------------- */

	$.easing.def = "easeInOutBack";


	$('#money-btn li a[href="#"]').click(function(e) {

		$(this).toggleClass('active-btn');
		var dropDown = $(this).next();

		$('#money-btn li p').not(dropDown).slideUp('slow');
		$('#money-btn li a[href="#"]').not($(this)).removeClass('active-btn');
		dropDown.slideToggle('slow');

		e.preventDefault();
	});
	$('#pricing-btn li a[href="#"]').click(function(e) {

		$(this).toggleClass('active-btn');
		var dropDown = $(this).next();

		$('#pricing-btn li div').not(dropDown).slideUp('slow');
		$('#pricing-btn li a[href="#"]').not($(this)).removeClass('active-btn');
		dropDown.slideToggle('slow');

		e.preventDefault();
	});


	/* -------------------- Table Vertical Hover ---------------------------- */


	$("#center .pricing-start .table-striped").delegate('td',
		'mouseover mouseleave', function(e) {
			if (e.type === 'mouseover') {
				$("colgroup").eq($(this).index()).addClass("hover");
			} else {
				$("colgroup").eq($(this).index()).removeClass("hover");
			}
		});

  /* -------------------- Fixed Menu ---------------------------- */
  var headerTop = $(".header-top"),
      headerTopHeight = headerTop.outerHeight();

  function fixHeader() {
    if ($(window).width() > 767 && !headerTop.hasClass('order-page')) {
      if ($(window).scrollTop() > (headerTopHeight + 50)) {
        $("body").css("padding-top", headerTopHeight).addClass("header-fixed__enabled");
        headerTop.addClass("header-top__fixed");
      } else {
        disableFixedHeader();
      }
    } else {
      disableFixedHeader();
    }
  }

  function disableFixedHeader() {
    if (headerTop.hasClass("header-top__fixed")) {
      $("body").css("padding-top", 0).removeClass("header-fixed__enabled");
      headerTop.removeClass("header-top__fixed");
    }
  }

  window.addEventListener("scroll", fixHeader);
  window.addEventListener("load", fixHeader);
  window.addEventListener("resize", fixHeader);


  /* -------------------- Smartphone Scroll Menu ---------------------------- */
	var nav_width = $('.width-navigation').width();
	var nav_window = $(window).width();
	var right_limit = -((nav_width + 80) - nav_window);
	var step;

	$('.nav-button-left').click(function(e) {

		step = $('.width-navigation').offset().left + 90;

		if ($('.width-navigation').offset().left > -41) {
			step = 40;
		}

		$('.width-navigation').animate({
			'left': step
		}, 500);
		e.preventDefault();
	});

	$('.nav-button-right').click(function(e) {
		step = $('.width-navigation').offset().left - 90;

		if ($('.width-navigation').offset().left < right_limit + 40) {
			step = right_limit;
		}

		$('.width-navigation').animate({
			'left': step
		}, 500);
		e.preventDefault();
	});
	var popolam = $(window).width() / 2;
	var popolam_object = $('.active').width() / 3;

	popolam = popolam - popolam_object;

	if ($('.width-navigation li a').is('.active')) {
		var aaa = -($('.active').offset().left - popolam);
	}

	if (aaa > 20) {
		aaa = 40;
	}
	if (aaa < right_limit + 20) {
		aaa = right_limit;
	}
	if ($('.active')) {
		$('.width-navigation').css({
			'left': aaa
		});
	}

	$(window).resize(function() {
		nav_width = $('.width-navigation').width();
		nav_window = $(this).width();
		right_limit = -((nav_width + 80) - nav_window);



		if ($('.width-navigation li a').is('.active')) {
			if (aaa > 20) {
				aaa = 40;
			}
			$('.width-navigation').css({
				'left': aaa
			});
		}
	});


	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});

	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');

});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '54',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
